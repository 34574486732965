import * as React from "react"

import Seo from "../../components/seo"
import CustomCompanyInfo from "../../components/CustomCompanyInfo/CustomCompanyInfo"
import { LeftAlign } from "../../components/Brand/SectionsTemplate"

import { Data } from "./constants"

import BannerImage from "../../assets/images/HeroBannerdata.png"

const DataPrivacyAndCookiesPolicy = ({ pageContext }) => (
  <>
    <Seo title="Data Privacy And Cookies Policy" lang="en"/> 
    <CustomCompanyInfo 
      pageContext={pageContext}
      pageTitle="data-privacy inside-pages"
      headerTitle="Data Privacy And Cookies Policy"
      bannerImageSrc={BannerImage}
      children={
        <>
          <LeftAlign details={Data} />
        </>
      }
    />
  </>
)

export default DataPrivacyAndCookiesPolicy
